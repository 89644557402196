export const CHANGE_IS_LOADING="CHANGE_IS_LOADING"

export const GET_LIST_PARENT_CATEGORY="GET_LIST_PARENT_CATEGORY"
export const CRUD_PARENT_CATEGORY="CRUD_PARENT_CATEGORY"
export const REMOVE_PARENT_CATEGORY="REMOVE_PARENT_CATEGORY"
export const ADD_PARENT_CATEGORY="ADD_PARENT_CATEGORY"
export const SELECTED_CATEGORY="SELECTED_CATEGORY"
export const CRUD_CATEGORY="CRUD_CATEGORY"
export const REMOVE_CATEGORY="REMOVE_CATEGORY"

export const RESET_MAIN_LAYOUT = "RESET_MAIN_LAYOUT"

export const TURN_ON_IS_LOADING="TURN_ON_IS_LOADING"
export const TURN_OFF_IS_LOADING="TURN_OFF_IS_LOADING"